<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>专家建议管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有专家建议" icon="ios-warning">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="suggestionManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增专家建议" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addSuggestion"
                :model="newSuggestion"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newSuggestion.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="建议内容：" prop="content">
                  <Input v-model="newSuggestion.content" placeholder="" />
                </FormItem>
                <FormItem label="评价年度：" prop="evaluate_year">
                  <input type="number" v-model="newSuggestion.evaluate_year" style="width: 200px"/>
                </FormItem>
                <FormItem label="专业组：" prop="major_system">
                  <Select
                    v-model="newSuggestion.major_system"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.majorSystemChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="图片上传：" prop="imgFile">

                  <div class="img-outer-wrapper">       
          <a v-for="(ele,index) in newSuggestion.imgUrl" :key="index+'img'" class="img-a" @click="delImg(index)">
            <img :src="ele" alt="" style="width:50px;">
            <span>点击删除</span>
            </a>
        <div class="img-wrapper">
            <Upload
              type="drag"
              action
              multiple
              :on-success="handleSuccess"
              :format="['jpg','jpeg','png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="(value)=>handleBeforeUpload(value)"
              style="width:100%;outline:none;margin:0 auto;border:none;"
            >
              <div style="width:100%;min-height:50px;">
                <p class="operation-text">+</p>
              </div>
            </Upload>
            </div>
          </div>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addSuggestion')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addSuggestion')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含安评专家建议相关数据的excel文件：
                  <a href="/public_files/templates/SuggestionTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/suggestion_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>专家建议信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterSuggestion"
                :model="currentSuggestion"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line">
                  <Select  v-model="currentSuggestion.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="专家建议内容：" prop="content">
                  <Input v-model="currentSuggestion.content" placeholder="" />
                </FormItem>
                <FormItem label="评价年度：" prop="evaluate_year">
                  <input type="number" v-model="currentSuggestion.evaluate_year" style="width: 200px"/>
                </FormItem>
                <FormItem label="所属专业：" prop="major_system">
                  <Select
                    v-model="currentSuggestion.major_system"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.majorSystemChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="图片上传：" prop="imgFile">

                  <div class="img-outer-wrapper">       
          <a v-for="(ele,index) in currentSuggestion.imgUrl" :key="index+'img'" class="img-a" @click="delImg(index)">
            <img :src="ele" alt="" style="width:50px;">
            <span>点击删除</span>
            </a>
        <div class="img-wrapper">
            <Upload
              type="drag"
              multiple
              action
              :on-success="handleSuccess"
              :format="['jpg','jpeg','png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="(value)=>handleBeforeUpload(value)"
              style="width:100%;outline:none;margin:0 auto;border:none;"
            >
              <div style="width:100%;min-height:50px;">
                <p class="operation-text">+</p>
              </div>
            </Upload>
            </div>
          </div>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterSuggestion')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");



// let authData = JSON.parse(localStorage.getItem("authData"));

function getObjectURL(file) {
  var url = null;
  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

function suggestionFac() {
    return {
    imgUrl: [],
    imgObj:[],
    id:0,
    line_code:'',
    line_name:'',
    content:'',
    evaluate_year:new Date().getFullYear(),
    major_system:'',
  };
  
}

export default {
  name: "suggestionManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      file: null,
      fileAlter: null,
      imgUrl:[],
      columnsFormatData: [
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 120,
        },
        {
          title: "专业组",
          key: "major_system",
          align: "center",
        },
        {
          title: "建议内容",
          key: "content",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 160,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      newSuggestion: suggestionFac(),
      currentSuggestion:suggestionFac(),
      ruleValidate: {
        line_code: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            type: "string",
            message: "请输入专家建议内容！",
            trigger: "blur",
          },
        ],
      },
      option:{
      majorSystemChoices:[

      ],
      },
      activeOption:{

      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    getStaticOption() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "suggestionManage",
        })
        .then((response) => {
            this.option = response.data.res_record;
        });
    },
     getActiveOption() {
    this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "allLineList",
        })
        .then((response) => {
          this.activeOption = {'lineChoices':response.data.res_record};
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.file = null;
      this.fileAlter = null;
    },
    handleRemove() {
      this.file = null;
      this.fileAlter = null;
    },
    handleSuccess() {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件 " +
          file.name +
          " 的格式不正确, 请选择 jpg 或 png."
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "超出文件大小",
        desc: "文件  " + file.name + " 太大, 应不大于2M."
      });
    },
    handleBeforeUpload(file) {
      this.newSuggestion.imgObj.push(file);
      this.currentSuggestion.imgObj.push(file);
      let objUrl = getObjectURL(file);
      if (objUrl) {
        this.newSuggestion.imgUrl.push(objUrl);
        this.currentSuggestion.imgUrl.push(objUrl);
      }
      return false;
    },
    delImg(i){
      this.newSuggestion.imgUrl.splice(i,1);
      this.newSuggestion.imgObj.splice(i,1);
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          for(let i of this.newSuggestion.imgObj){
            form.append("file", i);
          }
          this.newSuggestion.imgUrl = [];
          this.newSuggestion.imgObj = [];
          form.append("askData", "addSuggestion");
          form.append("newSuggestion", JSON.stringify(this.newSuggestion));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/suggestion_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("专家建议添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterSuggestion");
          for(let i of this.currentSuggestion.imgObj){
            form.append("file", i);
          }
          this.currentSuggestion.imgUrl = undefined;
          this.currentSuggestion.imgObj = undefined;
          form.append("currentSuggestion", JSON.stringify(this.currentSuggestion));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/suggestion_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("专家建议修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条专家建议信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "专家建议内容：" +
          value.content +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/suggestion_alter", {
          userToken: userToken,
          askData: "delSuggestion",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentSuggestion = suggestionFac();
        this.currentSuggestion.id = value.id;
        this.currentSuggestion.line_code = value.line_code;
        this.currentSuggestion.line_name = value.line_name;
        this.currentSuggestion.content = value.content;
        this.currentSuggestion.evaluate_year = value.evaluate_year;
        this.currentSuggestion.major_system = value.major_system.split('|');
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

.img-outer-wrapper{
  display: flex;
  flex-wrap:wrap;
  justify-content: left;

}

.img-wrapper {
  width: 50px;
  min-height: 50px;
  margin: 0 5px;
}

.img-wrapper-inner{
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
}

.img-a{
  border:1px solid #ccc;
  margin:0 5px;
  height: fit-content;
  position: relative;
}

.img-a span{
  display: none;
  position: absolute;
  font-size: 8px;
  left:0;
  background-color: #fff;
  top:5px;
}

.img-a:hover span{
  display: inline-block;
}

input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>